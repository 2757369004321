// extracted by mini-css-extract-plugin
export const sectionSpacing = "docentes-module--section-spacing--1QK0S";
export const rowSpacing = "docentes-module--row-spacing--2gQwo";
export const rowSpacingSml = "docentes-module--row-spacing-sml--tEPzP";
export const intro = "docentes-module--intro--3AeIk";
export const introGridBox = "docentes-module--introGridBox--2AMBd";
export const introBox = "docentes-module--introBox--26Hkh";
export const classRes = "docentes-module--classRes--3I3Es";
export const classResBox = "docentes-module--classResBox--2iXQ3";
export const extResources = "docentes-module--extResources--3cOZm";
export const extResBox = "docentes-module--extResBox--arYa-";
export const kits = "docentes-module--kits--1XuDb";