// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import Button from "../components/button";
import Card from "../components/cards/card-empty";
import Header from "../components/header";
import Kits from "../components/kits";
import Layout from "../components/layout";
import Resource from "../components/resource";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorBottomTeal from "../components/separators/separator-bottom-teal";
import SeparatorBottomYellow from "../components/separators/separator-bottom-yellow";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopTeal from "../components/separators/separator-top-teal";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import photoHead from "../images/docentes-page/doc-blob.png";
import photoHeadMob from "../images/docentes-page/doc-blob-mobile.png";
import * as styles from "./styles/docentes.module.scss";

const DocentesPage = ({data}) => (
<Layout>
    <Section color={"white"}>
        <Header bkgImage={photoHead} mobileBkgImage={photoHeadMob}>
            <h1>Docente: descubre la importancia de guiar a niñas en las STEM</h1>
        </Header>
    </Section>
    <Section color={"white"}>
        <div className={`container ${styles.intro}`}>
            <div className="row">
                <div className={`col-12 ${styles.introGridBox}`}>
                    <div className="imgHolder"></div>
                    <div className={styles.introBox}>
                        <h1>¿Qué son las STEM y por qué son importantes?</h1>
                        <h4>Las habilidades que se desarrollan con las STEM están directamente asociadas al uso y desarrollo de tecnologías, pero también a aspectos prácticos como la programación o el pensamiento computacional.</h4>
                        <Button url="/inspiracion/post-qué-son-las-stem-y-por-qué-son-importantes" color="turquoise" buttonText="Descubre aquí"/>
                    </div>
                </div>
            </div>
        </div>
    </Section>
    <SeparatorTopTeal />
    <Section color={"turquoise"}>
        <div className={`container ${styles.classRes}`}>
            <SectionHeader color="white">
                <h1>Recursos para el aula</h1>
                <h4>Descubre diferentes herramientas que te ayudarán a complementar la enseñanza STEM en la sala de clases.</h4>
            </SectionHeader>
            <div className={`row ${styles.classResBox}`}>
                {data.classRes.edges.map((edge) => (
                    <div key={edge.node.id} className="col-sm-12 col-md-6">
                        <Resource>
                            <div className="photo" key={edge.node.mainImage.asset.id}>
                                <Image 
                                    {...edge.node.mainImage}
                                    width={320}
                                    height={320}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                    alt="Imágen de Recurso para el aula" />
                            </div>
                            <div className="box">
                                <h4>{edge.node.title}</h4>
                                <div className="separator"></div>
                                <p>{edge.node.excerpt}</p>
                                <Link to={`/recursos-aula/${edge.node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                            </div>
                        </Resource>
                    </div>
                ))}
            </div>
        </div>
    </Section>
    <Section color={"yellow"}>
    <SeparatorBottomTeal />
        <div className={`container section-spacing ${styles.extResources}`}>
            <SectionHeader color="white">
                <h1>Recursos externos</h1>
                <h4>Te mostramos algunas herramientas en tecnología y ciencia que pueden ser útiles para usarlas en tus clases.</h4>
            </SectionHeader>
            <div className={`row ${styles.extResBox}`}>
                {data.extRes.edges.map((edge) => (              
                    <div key={edge.node.id} className="col-sm-12 col-md-6 col-lg-4">
                        <Card desc={edge.node.title} postUrl={`/recursos-externo/${edge.node.slug.current}`}/>
                    </div>
                ))}
            </div>
        </div>
    </Section>
    <SeparatorBottomYellow />
    <Section color={"white"}>
        <div className={`container section-spacing ${styles.kits}`}>
            <SectionHeader color="turquoise">
                <h1>Cajas interactivas</h1>
                <h4>Kit de Herramientas físicas que te permitirán realizar actividades y experimentos en ciencia y tecnología.</h4>
            </SectionHeader>
            <Kits/>
        </div>
    </Section>
    <SeparatorTopGrey />
</Layout>
);
export default DocentesPage;

export const query = graphql`
query DocentesClassResAndResExtQuery {
    classRes: allSanityPost (
    limit: 2, 
    filter: {category: {title: {eq: "Recursos Ingeniosas"}}}, 
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                slug {
                    current
                }
                excerpt
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
    extRes: allSanityPost (
    limit: 6, 
    filter: {category: {title: {eq: "Recursos externos"}} , tags: {elemMatch: {title: {eq: "Docentes"}}}},
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                slug {
                    current
                }
            }
        }
    }
}
`;